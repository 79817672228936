import { useQuery } from '@apollo/client'
import { Select } from '@components'
import { Input } from '@elements'
import { useGraphqlPurchaseRequest, useLocationWithState, useWindowSize } from '@hooks'
import BackArrow from '@images/back-arrow.svg'
import PriceTagIcon from '@images/price-tag.svg'
import { CheckoutLayout } from '@layouts'
import {
  equipment as graphqlEquipment,
  golfClubModel as graphqlGolfClubModel,
  golfClubType as graphqlGolfClubType,
  golfEquipment as graphqlGolfEquipment
} from '@graphql'
import {
  AddressCard,
  GolfClubModel,
  GolfClubType,
  GolfEquipmentModel,
  GolfEquipmentType,
  PaymentCard,
  ProductPageItemType,
  ResponseType
} from '@types'
import { addDays } from '@utils'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'

interface CheckoutSetBuyPriceProps {
  id: string
}

const EXPIRATION_DAYS = [
  { label: '10 Days', value: '10' },
  { label: '20 Days', value: '20' },
  { label: '30 Days', value: '30' }
]

const CheckoutSetBuyPrice: React.FC<CheckoutSetBuyPriceProps> = ({ id }) => {
  const { createPurchaseRequest, loading: createPurchaseRequestLoading } = useGraphqlPurchaseRequest()

  const { state } = useLocationWithState<{ viewPage: boolean; type: ProductPageItemType }>()

  const isGolfClubPage = state.type === 'GolfClubModel'

  const [golfModel, setGolfModel] = useState<GolfEquipmentModel | GolfClubModel>()

  const isBackPageView = state.viewPage

  const writtenPrice = isBackPageView ? 0 : parseInt(localStorage.getItem('writtenPrice') || '0')

  const [newGolfClubPrice, setNewGolfClubPrice] = useState<number | undefined>(writtenPrice)

  const [selectedExpirationDays, setSelectedExpirationDays] = useState<string>(EXPIRATION_DAYS[0].value)
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])

  /**
   * * isGolfClubPage === true - fetch golfClub Types
   * * isGolfClubPage === false - fetch golfEquipment Types
   */
  const golfItemTypeQuery = isGolfClubPage
    ? graphqlGolfClubType.FetchGolfClubType
    : graphqlEquipment.FetchGolfEquipmentType
  const { data: golfTypeData, loading: golfTypeLoading } = useQuery<ResponseType<GolfClubType | GolfEquipmentType>>(
    golfItemTypeQuery,
    { variables: { id } }
  )

  useEffect(() => {
    const golfType = golfTypeData?.res

    if (golfType) {
      if ('golfClubModel' in golfType) setGolfModel(golfType.golfClubModel)
      else if ('golfEquipmentModel' in golfType) setGolfModel(golfType.golfEquipmentModel)

      if (golfModel && golfModel.lowestSellPrice && !newGolfClubPrice) {
        setNewGolfClubPrice(golfModel.lowestSellPrice)
      }
    }
  }, [golfTypeData])

  const handleConfirm = async (addressCard?: AddressCard, userCard?: PaymentCard) => {
    const expirationDaysNumber = +selectedExpirationDays
    const expiresAt = addDays(new Date(), expirationDaysNumber).toISOString().split('T')[0]
    const price = newGolfClubPrice
    const returnPayload = () => {
      if (golfTypeData && expiresAt && price && addressCard && userCard) {
        if (golfModel?.__typename === 'GolfClubModel')
          return {
            addressCardId: addressCard.id,
            expiresAt,
            golfClubTypeId: golfTypeData.res.id,
            price,
            paymentCardId: userCard.id
          }

        return {
          addressCardId: addressCard.id,
          expiresAt,
          golfEquipmentTypeId: golfTypeData.res.id,
          price,
          paymentCardId: userCard.id
        }
      }
    }

    const payload = returnPayload()
    const refetchQuery = isGolfClubPage
      ? graphqlGolfClubModel.FetchGolfClubModel
      : graphqlGolfEquipment.FetchGolfEquipmentModel

    if (payload)
      await createPurchaseRequest(
        {
          purchaseRequest: payload
        },
        {
          refetchQueries: [
            {
              query: refetchQuery,
              variables: { id: golfModel?.id || '' }
            }
          ]
        }
      )
  }

  return (
    <CheckoutLayout
      golfTypeData={golfTypeData}
      golfTypeLoading={golfTypeLoading}
      handleConfirm={handleConfirm}
      price={newGolfClubPrice}
      title={
        <strong className="checkout-payment-info-title subtitle1">
          {isMobileRender && <BackArrow onClick={() => navigate(-1)} />}
          <PriceTagIcon />
          Make Offer
        </strong>
      }
      mutationLoading={createPurchaseRequestLoading}
    >
      <div className={'checkout-payment-note'}>
        You are only charged if your offer is accepted! Choose an expiration date for your offer and cancel anytime. We
        will notify you via email if your Offer is accepted. There are no fees for buyers.
      </div>
      <div className="checkout-payment-info-set-price-form">
        <div className="checkout-payment-info-set-price-form-field">
          <label>price</label>
          <Input
            placeholder="$0.00"
            // value={newGolfClubPrice?.toString() === '0' ? ' ' : newGolfClubPrice?.toString()}
            value={newGolfClubPrice?.toString()}
            onChange={val => {
              if (+val < 1000000) {
                setNewGolfClubPrice(+val)
              }
            }}
            type="text"
            theme="green"
          />
          <div className={'checkout-payment-info-set-price-form-field-warn'}>
            *You must meet the minimum offer of $15
          </div>
        </div>
        <div className="checkout-payment-info-set-price-form-field">
          <label>expiration</label>
          <Select
            name="expiration"
            options={EXPIRATION_DAYS}
            value={selectedExpirationDays}
            onChange={val => val && setSelectedExpirationDays(val)}
          />
        </div>
      </div>
    </CheckoutLayout>
  )
}

export default CheckoutSetBuyPrice
